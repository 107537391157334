import useQueryParams from "../../../hooks/useQueryParams";
import useUserData from "../../../hooks/user/useUserData";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CALCULATIONS } from "../locales/namespaces";
import * as yup from "yup";
import { API } from "../../../helpers/constants";
import { enqueueSnackbar } from "notistack";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_CALCULATIONS_EDIT } from "../routes/routes";
import { handleAxiosErrors, logoutUnauthorizedUser } from "../../../helpers/errorHandling";
import { useFormik } from "formik";
import axios from "axios";
import { TypeInterestRate, TypeInterestRateIds } from "../../../parameters/parameters";
import moment from "moment";
import { calculateInstallments, calculateLtv } from "../helpers/calculations";
import { useMemo, useState } from "react";
import useUserAction from "../../../hooks/user/useUserAction";

const useCalculationForm = ({ id, calculations, interests, contributions, repayments }) => {
	const params = useQueryParams()
	const { token, isLoggedIn } = useUserData()
	const { userLogout } = useUserAction()
	const history = useHistory()
	const { t } = useTranslation(CALCULATIONS)
	const [isLoading, setIsLoading] = useState(false);
	const mapRepayments = repayments.map(repayment => ({
		Amount: Number(repayment.Amount),
		NewRate: Number(repayment.NewRate),
		Repayment_dt: moment(repayment.PaymentDt).format('MM/DD/YYYY')
	}))
	
	const generalInitialValues = useMemo(() => ({
		RealEstateValue: Object.keys(calculations).length > 0 ? calculations?.RealEstateValue : interests.RealEstateValue,
		LoanAmount: calculations?.LoanAmount || interests.LoanAmount,
		Ltv: calculateLtv(calculations?.RealEstateValue || 100000, calculations?.LoanAmount || 80000),
		InterestType: calculations?.InterestType || TypeInterestRate.filter(f => f.isDefault === 1)[0].id,
		FixedInterestRate: Object.keys(calculations).length > 0 ? calculations?.FixedInterestRate : parseFloat(interests.StableInterest).toFixed(2),
		FixedDuration: Object.keys(calculations).length > 0 ? calculations?.FixedDuration : 30,
		Spread: Object.keys(calculations).length > 0 ? calculations?.Spread : 0/*interests.Spread*/,
		Euribor: Object.keys(calculations).length > 0 && (TypeInterestRateIds.Floating || TypeInterestRateIds.Hybrid) ? interests.Euribor : 0/*interests.Euribor*/,
		FloatingDuration: Object.keys(calculations).length > 0 ? calculations?.FloatingDuration : 0,
		Contribution: calculations?.ContributionId ? contributions.find(f => f.id === calculations.ContributionId)?.id : contributions.length > 0 ? contributions.find(f => f.isDefault === '1')?.id : '',
		TotalDuration: calculations?.TotalDuration || 0,
		InstallmentNumber: calculations?.InstallmentNumber || 0,
		DateInstallment: calculations?.DateInstallment ? moment(calculations.DateInstallment, "DD/MM/YYYY") : moment().startOf('day'),
		isMonthly: calculations?.isMonthly || 'isMonthly',
		isHidePreviousInstallments: calculations?.isHidePreviousInstallments || false,
		NotifyFlag: calculations?.NotifyFlag || false,
		Description: calculations?.Description || '',
		CTA: true
	}), [calculations, contributions, interests.Euribor, interests.LoanAmount, interests.RealEstateValue, interests.StableInterest])
	
	const initialValuesFromUrl = useMemo(() => {
		const initValues = {}
		for (const [key, value] of params.entries()) {
			if (key === 'RealEstateValue' || key === 'Euribor' || key === 'FixedDuration' || key === 'FloatingDuration' || key === 'LoanAmount' || key === 'RealEstateValue' || key === 'TotalDuration' || key === 'InstallmentNumber') {
				initValues[key] = value ? parseFloat(value) : 0
			} else if (key === 'NotifyFlag' || key === 'isHidePreviousInstallments') {
				initValues[key] = value === 'true'
			} else if (key === 'DateInstallment') {
				initValues[key] = moment(value)
			} else if (key === 'InterestType') {
				initValues[key] = Number(value)
			} else if (key === 'CTA') {
				initValues[key] = value === '1'
			} else {
				initValues[key] = value
			}
		}
		if(!initValues.hasOwnProperty('CTA')) initValues.CTA = true
		if(!initValues.hasOwnProperty('RealEstateValue')) initValues.RealEstateValue = generalInitialValues.RealEstateValue
		if(!initValues.hasOwnProperty('LoanAmount')) initValues.LoanAmount = generalInitialValues.LoanAmount
		if(!initValues.hasOwnProperty('Ltv')) initValues.Ltv = generalInitialValues.Ltv
		if(!initValues.hasOwnProperty('InterestType')) initValues.InterestType = generalInitialValues.InterestType
		if(!initValues.hasOwnProperty('FixedInterestRate')) initValues.FixedInterestRate = generalInitialValues.FixedInterestRate
		if(!initValues.hasOwnProperty('FixedDuration')) initValues.FixedDuration = generalInitialValues.FixedDuration
		if(!initValues.hasOwnProperty('Spread')) initValues.Spread = generalInitialValues.Spread
		if(!initValues.hasOwnProperty('Euribor')) initValues.Euribor = generalInitialValues.Euribor
		if(!initValues.hasOwnProperty('FloatingDuration')) initValues.FloatingDuration = generalInitialValues.FloatingDuration
		if(!initValues.hasOwnProperty('Contribution')) initValues.Contribution = generalInitialValues.Contribution
		if(!initValues.hasOwnProperty('TotalDuration')) initValues.TotalDuration = generalInitialValues.TotalDuration
		if(!initValues.hasOwnProperty('InstallmentNumber')) initValues.InstallmentNumber = generalInitialValues.InstallmentNumber
		if(!initValues.hasOwnProperty('DateInstallment')) initValues.DateInstallment = generalInitialValues.DateInstallment
		if(!initValues.hasOwnProperty('isMonthly')) initValues.isMonthly = generalInitialValues.isMonthly
		if(!initValues.hasOwnProperty('isHidePreviousInstallments')) initValues.isHidePreviousInstallments = generalInitialValues.isHidePreviousInstallments
		if(!initValues.hasOwnProperty('NotifyFlag')) initValues.NotifyFlag = generalInitialValues.NotifyFlag
		if(!initValues.hasOwnProperty('Description')) initValues.Description = generalInitialValues.Description
		return initValues
	}, [generalInitialValues.Contribution, generalInitialValues.DateInstallment, generalInitialValues.Description, generalInitialValues.Euribor, generalInitialValues.FixedDuration, generalInitialValues.FixedInterestRate, generalInitialValues.FloatingDuration, generalInitialValues.InstallmentNumber, generalInitialValues.InterestType, generalInitialValues.LoanAmount, generalInitialValues.Ltv, generalInitialValues.NotifyFlag, generalInitialValues.RealEstateValue, generalInitialValues.Spread, generalInitialValues.TotalDuration, generalInitialValues.isHidePreviousInstallments, generalInitialValues.isMonthly, params])
	
	const initialValues = useMemo(() => {
		if (Object.keys(initialValuesFromUrl).length > 0) {
			return { ...initialValuesFromUrl }
		} else {
			return { ...generalInitialValues }
		}
	}, [generalInitialValues, initialValuesFromUrl])

	const [results, setResults] = useState(calculateInstallments(
		0,
		initialValues.FixedDuration,
		initialValues.FloatingDuration,
		initialValues.FixedInterestRate,
		initialValues.Spread + initialValues.Euribor,
		contributions.find(f => f.id === initialValues.Contribution)?.Value,
		initialValues.LoanAmount,
		initialValues.isMonthly === 'isMonthly',
		initialValues.isHidePreviousInstallments,
		moment(initialValues.DateInstallment).format('MM/DD/YYYY'),
		[...mapRepayments],
		[],
		[],
		0,
		0,
		0,
		0,
		[...mapRepayments],
	))
	
	const handleResults = (updateResults) => setResults(updateResults)
	
	const validationSchema = yup.object({
		RealEstateValue: yup.number().positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(99999999.99, t('Real estate value must be less than 100,000,000.')),
		LoanAmount: yup.number().required(t('Required')).positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(99999999.99, t('Loan amount must be less than 100,000,000.')),
		FixedInterestRate: yup.number().positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(99, t('The value is too big.')).test(
			'Required', t('Required'),
			function (value) {
				return !(((this.parent['InterestType'] === 1 || this.parent['InterestType'] === 3) && this.parent['FixedInterestRate'] === undefined) && this.parent['InterestType'] !== 2)
			}
		),
		FixedDuration: yup.number().integer(t('Value must be an integer number.')).min(0, t('Value must be a positive number.')).max(45, t('The value is too big.')).test(
			'Required', t('Required'),
			function (value) {
				// console.log('value', value)
				return !(((this.parent['InterestType'] === 1 || this.parent['InterestType'] === 3) && this.parent['FixedDuration'] === undefined) && this.parent['InterestType'] !== 2)
			}
		).test('sum', t('The total duration of the loan cannot exceed 45 years'), function (value) {
			const { FixedDuration, FloatingDuration } = this.parent;
			if (FixedDuration !== undefined && FloatingDuration !== undefined) {
				return FixedDuration + FloatingDuration <= 45;
			}
			return true;
		}),
		Spread: yup.number().positive(t('Value must be a positive number.')).min(0, t('Value must be a positive number.')).max(100, t('The value is too big.'))
		.test(
			'Required', t('Required'),
			function (value) {
				return !(((this.parent['InterestType'] === 2 || this.parent['InterestType'] === 3) && this.parent['Spread'] === undefined) && this.parent['InterestType'] !== 1)
			}
		),
		FloatingDuration: yup.number().integer(t('Value must be an integer number.')).min(0, t('Value must be a positive number.')).max(45, t('The value is too big.')).test(
			'Required', t('Required'),
			function (value) {
				return !(((this.parent['InterestType'] === 2 || this.parent['InterestType'] === 3) && this.parent['FloatingDuration'] === undefined) && this.parent['InterestType'] !== 1)
			}
		).test('sum', t('The total duration of the loan cannot exceed 45 years'), function (value) {
			const { FixedDuration, FloatingDuration } = this.parent;
			if (FixedDuration !== undefined && FloatingDuration !== undefined) {
				return FixedDuration + FloatingDuration <= 45;
			}
			return true;
		}),
		DateInstallment: yup.date().typeError(t('Value must be a valid date.')).required(t('Required'))
	})
	
	const onSubmit = (values) => {
		setIsLoading(true)
		axios({
			method: 'post',
			data: {
				CalculationId: id,
				RealEstateValue: values.RealEstateValue,
				RequestedAmount: values.LoanAmount,
				TypeId: values.InterestType,
				FixedRate: values.FixedInterestRate,
				FixedDuration: values.FixedDuration,
				Euribor: values.Euribor,
				Spread: values.Spread,
				FloatDuration: values.FloatingDuration,
				ContributionId: values.Contribution,
				isMonthly: values.isMonthly === 'isMonthly',
				HidePrevious: values.isHidePreviousInstallments,
				NotifyFlag: values.NotifyFlag,
				Description: values.Description,
				StartDt: values.DateInstallment ? moment(values.DateInstallment).format('YYYY-MM-DD') : null,
			},
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: !id ? `${API}/calculation/store` : `${API}/calculation/update`
		}).then(response => {
			enqueueSnackbar(!id ? t('Calculation Stored Successfully!') : t('Calculation Updated Successfully!'), {
				variant: 'success',
				onClose: () => history.push(getRouteUrl(ROUTE_PAGE_CALCULATIONS_EDIT, { id: response.data.CalculationId }))
			})
			setIsLoading(false)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get calculation.')), {
					variant: 'error'
				}
			)
			setIsLoading(false)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: onSubmit
	})
	
	// Handler when I change the value of LoanAmount and calculate the Ltv
	const handleCalculationsLoanAmount = (event, fieldName) => {
		const { value } = event.target;
		const otherFieldName = fieldName === "LoanAmount" ? "RealEstateValue" : "LoanAmount";
		const otherFieldValue = parseInt(formik.values[otherFieldName]);
		const Ltv = calculateLtv(otherFieldValue, value)
		formik.setFieldValue(fieldName, value);
		formik.setFieldValue("Ltv", Ltv);
		handleCalculateInstallments({
			target: {
				name: fieldName,
				value: value,
			},
		});
	};
	
	// Handler when I change the value of RealEstateValue and calculate the Ltv
	const handleCalculationsRealEstateValue = (event, fieldName) => {
		const { value } = event.target;
		const otherFieldName = fieldName === "RealEstateValue" ? "LoanAmount" : "RealEstateValue";
		const otherFieldValue = parseInt(formik.values[otherFieldName]);
		const Ltv = calculateLtv(value, otherFieldValue)
		formik.setFieldValue(fieldName, value);
		formik.setFieldValue("Ltv", Ltv);
	};
	
	const TotalDuration = Number(formik.values.FixedDuration) + Number(formik.values.FloatingDuration)
	const InstallmentNumber = TotalDuration * 12
	
	const handleCalculateInstallments = (event) => {
		const { name, value, checked } = event.target;
		formik.setFieldValue(name, value === 'on' ? checked : value);
		setResults(calculateInstallments(
			0,
			name === 'FixedDuration' ? value : formik.values.FixedDuration,
			name === 'FloatingDuration' ? value : formik.values.FloatingDuration,
			name === 'FixedInterestRate' ? value : formik.values.FixedInterestRate,
			name === 'Spread' ? Number(value) + Number(formik.values.Euribor) : Number(formik.values.Spread) + Number(formik.values.Euribor),
			name === 'Contribution' ? contributions.find(f => f.id === value).Value : contributions.find(f => f.id === formik.values.Contribution).Value,
			name === 'LoanAmount' ? value : formik.values.LoanAmount,
			name === 'isMonthly' ? value === 'isMonthly' : formik.values.isMonthly === 'isMonthly',
			name === 'isHidePreviousInstallments' ? checked : formik.values.isHidePreviousInstallments,
			name === 'DateInstallment' ? moment(value).format('MM/DD/YYYY') : moment(formik.values.DateInstallment).format('MM/DD/YYYY'),
			[...mapRepayments],
			[],
			[],
			0,
			0,
			0,
			0,
			[...mapRepayments]
		));
	};
	
	const handleInterestType = (event) => {
		const { value } = event.target;
		const interestType = TypeInterestRate.find((item) => item.id === Number(value));
		const updateValues = {}
		if (interestType.DescriptionEn === 'Fixed') {
			updateValues.FixedInterestRate = parseFloat(interests.StableInterest).toFixed(2)
			updateValues.FixedDuration = 30
			updateValues.Euribor = ''
			updateValues.Spread = ''
			updateValues.FloatingDuration = 0
		} else if (interestType.DescriptionEn === 'Floating') {
			updateValues.FixedInterestRate = ''
			updateValues.FixedDuration = 0
			updateValues.Euribor = interests.Euribor
			updateValues.Spread = parseFloat(interests.Spread).toFixed(2)
			updateValues.FloatingDuration = 30
		} else if (interestType.DescriptionEn === 'Fixed and Floating') {
			updateValues.FixedInterestRate = parseFloat(interests.StableInterest).toFixed(2)
			updateValues.FixedDuration = 15
			updateValues.Euribor = interests.Euribor
			updateValues.Spread = parseFloat(interests.Spread).toFixed(2)
			updateValues.FloatingDuration = 15
		}
		formik.setValues({
			...formik.values,
			...updateValues,
			InterestType: value
		});
		setResults(calculateInstallments(
			0,
			updateValues.FixedDuration,
			updateValues.FloatingDuration,
			updateValues.FixedInterestRate,
			parseFloat(updateValues.Spread) + updateValues.Euribor,
			formik.values.Contribution ? contributions.find(f => f.id === formik.values.Contribution).Value : contributions.find(f => f.isDefault === '1').Value,
			formik.values.LoanAmount,
			formik.values.isMonthly === 'isMonthly',
			formik.values.isHidePreviousInstallments,
			moment(formik.values.DateInstallment).format('MM/DD/YYYY'),
			[...mapRepayments],
			[],
			[],
			0,
			0,
			0,
			0,
			[...mapRepayments]
		))
	};
	
	const handleDate = (newValue) => {
		handleCalculateInstallments({
			target: {
				name: 'DateInstallment',
				value: newValue,
			},
		});
	}
	
	const handleResetForm = () => {
		const resetValues = {
			...initialValues,
			LoanAmount: initialValues.LoanAmount,
			RealEstateValue: interests.RealEstateValue,
			// Ltv: calculateLtv(100000, 80000),
			Ltv: calculateLtv(interests.RealEstateValue, initialValues.LoanAmount),
			InterestType: TypeInterestRate.filter(f => f.isDefault === 1)[0].id,
			FixedInterestRate: parseFloat(interests.StableInterest).toFixed(2),
			FixedDuration: 30,
			Euribor: initialValues.Euribor,
			Spread: initialValues.Spread,
			FloatingDuration: 0,
			Contribution: contributions.length > 0 ? contributions.find(f => f.isDefault === '1')?.id : '',
			TotalDuration: 0,
			InstallmentNumber: 0,
			DateInstallment: moment().startOf('day'),
			isMonthly: 'isMonthly',
			isHidePreviousInstallments: false
		};
		
		formik.resetForm({ values: resetValues });
		
		setResults(
			calculateInstallments(
				0,
				resetValues.FixedDuration,
				resetValues.FloatingDuration,
				resetValues.FixedInterestRate,
				resetValues.Spread + resetValues.Euribor,
				contributions.find((f) => f.id === resetValues.Contribution)?.Value,
				resetValues.LoanAmount,
				resetValues.isMonthly === "isMonthly",
				resetValues.isHidePreviousInstallments,
				moment(resetValues.DateInstallment).format("MM/DD/YYYY"),
				[],
				[],
				[],
				0,
				0,
				0,
				0,
				[]
			)
		);
	};

	return {
		formik,
		results,
		TotalDuration,
		InstallmentNumber,
		handleDate,
		handleResults,
		handleInterestType,
		handleCalculateInstallments,
		handleCalculationsLoanAmount,
		handleCalculationsRealEstateValue,
		handleResetForm,
		isLoading
	}
}
export default useCalculationForm