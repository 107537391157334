import { Box, Typography, List, ListItem, ListItemText, Link, Stack, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PAGE_LOGIN } from "../../locales/pages/namespaces";
import React, { useEffect } from "react";
import MainCard from "../../theme/mantis/components/mainCard";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

const PrivacyPolicy = ({ handleCloseModal, isModal }) => {
	const { t } = useTranslation(PAGE_LOGIN)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Privacy Policy')
	}, [t])
	
	const openLink = async (link) => {
		if (Capacitor.isNativePlatform()) {
			// Open link in in-app browser on native app
			await Browser.open({ url: link });
		} else {
			// Open link in new tab in web app
			window.open(link, '_blank');
		}
	};
	
	return (
		<Box
			sx={{
				mb: 1,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-end'
			}}
		>
			{isModal ?
				<Button
					sx={{
						m: 1,
						textAlign: 'right'
					}}
					//variant={'outlined'}
					onClick={handleCloseModal}
					color={'blue'}
				>
					{t("X")}
				</Button>
				: null
			}
			<MainCard
				title={
					<Typography variant="h4">
						{t('Privacy Statement')}
					</Typography>
				}
				sx={{
					mt: isModal ? 0 : 2
				}}
			>
				<Typography
					variant="h5">{t('This privacy statement was last updated on July 4, 2024 and applies to citizens and legal permanent residents of the European Economic Area and Switzerland.')}</Typography>
				<Box sx={{ mt: 1 }}>
					<Typography variant="h5"
								component={'span'}>{t('In this privacy statement, we explain what we do with the data we obtain about you via ')}</Typography>
				</Box>
				<Link
					component="button"
					onClick={() => openLink(t('ims_app_link'))}
					rel="noopener"
					underline="hover"
					color={'#485776'}
					sx={{
						textDecorationLine: 'underline',
						fontWeight: 700
					}}
				>
					https://app.ims-fc.gr
				</Link>
				<Typography variant="h5"
							component={'span'}>{t('. We recommend you carefully read this statement. In our processing we comply with the requirements of privacy legislation. That means, among other things, that')}</Typography>:
				<Typography variant="h5">
					<List
						sx={{
							listStyleType: 'disc',
							pl: 2,
							'& .MuiListItem-root': {
								display: 'list-item',
								color: '#485776'
							},
							ml: 1
						}}
					>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we clearly state the purposes for which we process personal data. We do this by means of this privacy statement;')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we aim to limit our collection of personal data to only the personal data required for legitimate purposes;')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we first request your explicit consent to process your personal data in cases requiring your consent;')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we take appropriate security measures to protect your personal data and also require this from parties that process personal data on our behalf;')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('we respect your right to access your personal data or have it corrected or deleted, at your request.')}/>
						</ListItem>
					</List>
				</Typography>
				<Typography
					variant="h5">{t('If you have any questions, or want to know exactly what data we keep of you, please contact us via dpo@ims-fc.gr.')}</Typography>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('1. Purpose, data and retention period')}
					</Typography>
					<Typography
						variant={'h5'}>{t('We may collect or receive personal information for a number of purposes connected with our business operations which may include the following')}:</Typography>
					<Link
						component={'button'}
						rel="noopener"
						underline="hover"
						sx={{
							mt: 1,
							color: '#485776',
							fontSize: '14px',
							fontStyle: 'normal',
							lineHeight: '22px',
							textDecorationLine: 'underline',
							textAlign: 'left'
						}}
					>
						<Typography
							variant={'h5'}
							fontWeight={700}
						>
							{t('1.1 Contact - Through phone, mail, email and/or webforms')}
						</Typography>
					</Link>
					<Typography
						variant={'h5'}
					>
						{t('using_data')}
					</Typography>
					<Box sx={{ mt: 1 }}>
						<Typography
							variant="h5"
						>
							<List
								sx={{
									listStyleType: 'disc',
									pl: 2,
									'& .MuiListItem-root': {
										display: 'list-item',
										color: '#485776'
									},
									ml: 1
								}}
							>
								<ListItem disablePadding>
									<ListItemText primary={t('Full Name,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Email Address,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Mobile Phone Number,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Age,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Nationality,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Country of Residence,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Citizenship,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Country of Taxation,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Marital Status,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Number of Dependents,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Annual Declared Personal/Family Income,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Financial Obligations (credit cards, loans, etc.),')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Purpose of Financing,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Financing Amount,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Purchase Price or Property Value,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText primary={t('Property Location for Purchase,')}/>
								</ListItem>
								<ListItem disablePadding>
									<ListItemText
										primary={t('Whether the User Has Already Selected a Property for Purchase.')}/>
								</ListItem>
							</List>
						</Typography>
					</Box>
					<Link
						component={'button'}
						rel="noopener"
						underline="hover"
						sx={{
							color: '#485776',
							fontSize: '14px',
							fontStyle: 'normal',
							lineHeight: '22px',
							textDecorationLine: 'underline',
							textAlign: 'left'
						}}
					>
						<Typography variant={'h5'} fontWeight={700}>{t('1.2 Data Collection During Use of the Service')}</Typography>
					</Link>
					<Box sx={{ mt: 1 }}>
						<Typography variant="h5">{t('Information related to your computer, including your IP address, geographical location, browser type and version, and operating system.')}</Typography>
					</Box>
					<Box sx={{ mt: 1 }}>
						<Typography variant="h5">{t('Information about your visits and use of the website, including the referring source, duration of the visit, page views, and navigation path on the website.')}</Typography>
					</Box>
					<Box sx={{ mt: 1 }}>
						<Typography variant="h5">{t('Any other personal information you send to us.')}</Typography>
					</Box>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('2. Cookies')}
					</Typography>
					<Typography
						variant={'h5'}>{t('Our website uses cookies. For more information about cookies, please refer to our Cookie Policy')}</Typography>
					<Link
						component="button"
						onClick={() => openLink('https://ims-fc.gr/en/politiki-cookies/')}
						rel="noopener"
						underline="hover"
						color={'#485776'}
						sx={{
							textDecorationLine: 'underline',
							fontWeight: 700
						}}
					>
						https://ims-fc.gr/en/politiki-cookies/
					</Link>.
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('3. Disclosure practices')}
					</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						variant={'h5'}>{t('We disclose personal information if we are required by law or by a court order, in response to a law enforcement agency, to the extent permitted under other provisions of law, to provide information, or for an investigation on a matter related to public safety.')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						variant={'h5'}>{t('If our website or organisation is taken over, sold, or involved in a merger or acquisition, your details may be disclosed to our advisers and any prospective purchasers and will be passed on to the new owners.')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						variant={'h5'}>{t('We have concluded a data Processing Agreement with Google.')}</Typography>
					<Typography variant={'h5'}>{t('The inclusion of full IP addresses is blocked by us.')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('4. Security')}
					</Typography>
					<Typography
						variant={'h5'}>{t('We are committed to the security of personal data. We take appropriate security measures to limit abuse of and unauthorized access to personal data. This ensures that only the necessary persons have access to your data, that access to the data is protected, and that our security measures are regularly reviewed.')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('5. Third-party websites')}
					</Typography>
					<Typography
						variant={'h5'}>{t('This privacy statement does not apply to third-party websites connected by links on our website. We cannot guarantee that these third parties handle your personal data in a reliable or secure manner. We recommend you read the privacy statements of these websites prior to making use of these websites.')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('6. Amendments to this privacy statement')}
					</Typography>
					<Typography
						variant={'h5'}>{t('We reserve the right to make amendments to this privacy statement. It is recommended that you consult this privacy statement regularly in order to be aware of any changes. In addition, we will actively inform you wherever possible.')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('7. Accessing and modifying your data')}
					</Typography>
					<Typography
						variant={'h5'}>{t('If you have any questions or want to know which personal data we have about you, please contact us. You can contact us by using the information below. You have the following rights')}:</Typography>
					<Typography variant="body2" color="text.secondary" style={{ fontSize: '1rem' }} component={'div'}>
						<List
							sx={{
								listStyleType: 'disc',
								pl: 2,
								'& .MuiListItem-root': {
									display: 'list-item',
									color: '#485776'
								},
								ml: 1
							}}
						>
							<ListItem disablePadding>
								<ListItemText
									primary={t('You have the right to know why your personal data is needed, what will happen to it, and how long it will be retained for.')}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText
									primary={`${t('Right of access')}: ${t('You have the right to access your personal data that is known to us.')}`}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText
									primary={`${t('Right to rectification')}: ${t('You have the right to supplement, correct, have deleted or blocked your personal data whenever you wish.')}`}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText
									primary={t('If you give us your consent to process your data, you have the right to revoke that consent and to have your personal data deleted.')}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText
									primary={`${t('Right to transfer your data')}: ${t('You have the right to request all your personal data from the controller and transfer it in its entirety to another controller.')}`}/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemText
									primary={`${t('Right to object')}: ${t('You may object to the processing of your data. We comply with this, unless there are justified grounds for processing.')}`}/>
							</ListItem>
						</List>
					</Typography>
					<Typography
						variant={'h5'}>{t('Please make sure to always clearly state who you are, so that we can be certain that we do not modify or delete any data of the wrong person.')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('8. Submitting a complaint')}
					</Typography>
					<Typography
						variant={'h5'}>{t('If you are not satisfied with the way in which we handle (a complaint about) the processing of your personal data, you have the right to submit a complaint to the Data Protection Authority.')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('9. Data Protection Officer')}
					</Typography>
					<Typography
						variant={'h5'}>{t('Our Data Protection Officer has been registered with the data protection authority in an EU Member State. If you have any questions or requests with respect to this privacy statement or for the Data Protection Officer, via www.ims-fc.gr or dpo@ims-fc.gr or by telephone on +30 210 9520047')}</Typography>
				</Box>
				<Box sx={{ mt: 1 }}>
					<Typography
						gutterBottom
						variant="body"
						fontWeight={700}
						component="div"
					>
						{t('10. Contact details')}
					</Typography>
					<Typography variant={'h5'}>Κ. ΚΑΜΠΟΥΡΗΣ – Ι. ΜΑΥΡΑΚΗΣ Α.Ε.</Typography>
					<Typography variant={'h5'}>{t('240 Andrea Syggrou Street, PO Box 176 72 Kallithea')}</Typography>
					<Typography variant={'h5'}>{t('Greece')}</Typography>
					<Stack direction={'row'} spacing={1}>
						<Typography variant={'h5'}>{t('website')}:</Typography>
						<Link
							component="button"
							onClick={() => openLink(t('ims_app_link'))}
							rel="noopener"
							underline="hover"
							color={'#485776'}
							sx={{
								textDecorationLine: 'underline',
								fontWeight: 700
							}}
						>
							https://app.ims-fc.gr
						</Link>
					</Stack>
					<Typography variant={'h5'}>{`${t('Email')}: dpo@ims-fc.gr`}</Typography>
					<Typography variant={'h5'}>{`${t('Phone number')}: +30 210 9520047`}</Typography>
				</Box>
			</MainCard>
		</Box>
	)
}

export default PrivacyPolicy;