// export function copyToClipboard(text) {
// 	if (window.clipboardData && window.clipboardData.setData) {
// 		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
// 		return window.clipboardData.setData("Text", text);
//
// 	} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
// 		var textarea = document.createElement("textarea");
// 		textarea.textContent = text;
// 		textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
// 		document.body.appendChild(textarea);
// 		textarea.select();
// 		try {
// 			return document.execCommand("copy");  // Security exception may be thrown by some browsers.
// 		} catch (ex) {
// 			console.warn("Copy to clipboard failed.", ex);
// 			return prompt("Copy to clipboard: Ctrl+C, Enter", text);
// 		} finally {
// 			document.body.removeChild(textarea);
// 		}
// 	}
// }

export function copyToClipboard(text) {
	// Uses the modern Clipboard API if available
	if (navigator.clipboard && navigator.clipboard.writeText) {
		const textarea = document.createElement("textarea");
		textarea.textContent = text;
		textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		navigator.clipboard.writeText(text).then(function() {
			console.log('Text copied to clipboard successfully!');
		}).catch(function(err) {
			console.warn("Copy to clipboard failed.", err);
			return prompt("Copy to clipboard: Ctrl+C, Enter", text);
		});
	// Falls back to the Internet Explorer-specific method if needed.
	} else if (window.clipboardData && window.clipboardData.setData) {
		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		return window.clipboardData.setData("Text", text);
	} else {
		console.warn('Clipboard API not supported. Fallback to prompt.');
		return prompt("Copy to clipboard: Ctrl+C, Enter", text);
	}
}