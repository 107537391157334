import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {useTranslation} from "react-i18next";
import {ALERTS} from "../../../locales/components/namespaces";
import useUserData from "../../../hooks/user/useUserData";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import useUserAction from "../../../hooks/user/useUserAction";

const useContribution = () => {
    const {t} = useTranslation(ALERTS)
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const [loading, setLoading] = useState(false)
    const [contributions, setContributions] = useState([])
    const [interests, setInterests] = useState({})

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'post',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/interests/edit/data`,
        }).then((response) => {
            setContributions(response.data.contributions)
            let data = response.data
            if (data && data.interests.length > 0) {
                const interests = data.interests[0]
                setInterests({
                    Euribor: Number(interests.Euribor),
                    Spread: Number(interests.Spread),
                    StableInterest: Number(interests.StableInterest),
                    RealEstateValue: Number(interests.RealEstateValue),
                    LoanAmount: Number(interests.RequestedAmount),
                })
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to get contribution.'), {
                        variant: 'error'
                    }
                )
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }, [])

    return {
        loading,
        contributions,
        interests
    }
}

export default useContribution
